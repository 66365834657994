var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c("panorama-block", { attrs: { "panorama-url": _vm.panoramaUrl } }),
      _c("best-apartments-block"),
      _c("slider-news", {
        attrs: { title: "Последние Новости", navigation: true },
      }),
      _c("callback-block", {
        attrs: {
          id: "callback",
          "id-formblock": "question-block__callback",
          theme: "white",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }