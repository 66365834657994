var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "panorama-block" },
    [
      _c(
        "content-wrapper-block",
        [
          _c("bread-crumbs"),
          _c("page-title", { attrs: { white: "" } }, [_vm._v(" Панорама ")]),
        ],
        1
      ),
      _c("iframe", {
        staticClass: "build-panorama",
        attrs: { src: _vm.panoramaUrl },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }