<template>
  <div class="panorama-block">
    <content-wrapper-block>
      <bread-crumbs />
      <page-title white> Панорама </page-title>
    </content-wrapper-block>
    <iframe :src="panoramaUrl" class="build-panorama" />
  </div>
</template>

<script>
import ContentWrapperBlock from "@/components/blocks/ContentWrapperBlock";
import PageTitle from "@/components/elements/PageTitle";
import "@black40x/vue-pano/dist/vue-pano.css";
import BreadCrumbs from "@/components/elements/BreadCrumbs";

export default {
  name: "PanoramaBlock",
  components: {
    BreadCrumbs,
    ContentWrapperBlock,
    PageTitle,
  },
  props: {
    panoramaUrl: String,
  },
};
</script>

<style lang="scss">
.panolens-infospot {
  font-size: 13px;
  font-family: $font-family-rubik;
  color: #1e1919 !important;
  text-shadow: unset !important;
  padding: 5px;
  background: #ffffffa6;
  border-radius: 5px;
}
</style>

<style lang="scss" scoped>
.panorama-block {
  background: #231f20;
  .build-panorama {
    height: calc(100vh - 214px);
    width: 100%;
    outline: 0;
    border: 0;
  }
}
</style>
