<template>
  <div class="">
    <panorama-block :panorama-url="panoramaUrl" />
    <best-apartments-block />
    <slider-news title="Последние Новости" :navigation="true" />
    <callback-block
      id="callback"
      id-formblock="question-block__callback"
      theme="white"
    />
  </div>
</template>

<script>
import PanoramaBlock from "@/components/blocks/PanoramaBlock";
import BestApartmentsBlock from "@/components/blocks/BestApartmentsBlock";
import SliderNews from "@/components/blocks/SliderNews";
import CallbackBlock from "@/components/blocks/CallbackBlock";

export default {
  name: "PanoramaPage",
  components: {
    PanoramaBlock,
    BestApartmentsBlock,
    SliderNews,
    CallbackBlock,
  },
  metaInfo: {
    meta: [
      {
        name: "description",
        content: "Обзор окружения на панораме вокруг ЖК MainStreet. ",
      },
    ],
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === "FilterPage" || !from.name) {
      next((vm) => {
        vm.panoramaUrl = vm.panoramaUrl + "?lastPosition";
      });
    } else next();
  },
  data() {
    return {
      panoramaUrl: process.env.VUE_APP_PANORAMA_IFRAME_URL,
    };
  },
};
</script>

<style lang="scss" scoped></style>
